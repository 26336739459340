<template>
    <div class="d-flex flex-column h-100">
        <div class="d-flex justify-content-between flex-shrink-0" v-if="chat">
            <div class="fw-semibold text-purple hstack gap-2 px-3" style="padding: .86em">
                <b-avatar class="bg-secondary text-white" size="3em" :src="chat.otherOperators[0].photoURL" />
                {{ chat.otherOperators[0].name }}
            </div>
            <div class="d-flex align-items-center p-3">
                <b-dropdown menu-class="dropdownanexes" toggle-class="p-0 border-0 bg-transparent bg-transparent2" no-caret no-flip v-if="operator && !operator.disableMedia">
                    <template #button-content>
                        <b-icon class="panelicon" icon="paperclip" style="transform: rotate(45deg)" scale="1.4" />
                    </template>
                    <b-dropdown-item-button class="rounded-circle mt-0 dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(291deg 55% 58%)" @click="toggleInsertFile('image/*')" v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Fotos">
                        <b-icon icon="image-fill"/>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(88deg 50% 56%)" @click="toggleInsertFile('.mp4')" v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Vídeos">
                        <b-icon icon="camera-video-fill"/>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(236deg 53% 59%)" 
                        @click="toggleInsertFile('application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow')" 
                        v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Arquivo"
                    >
                    <b-icon icon="file-earmark-fill"/>
                    </b-dropdown-item-button>
                    <!-- <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(33deg 86% 57%)" v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Localização">
                        <b-icon icon="geo-alt-fill"/>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button class="rounded-circle dropdownanexesitem" button-class="dropdownanexesitembtn" style="background-color: hsl(199deg 94% 50%)" v-b-tooltip.hover.left="{ customClass: 'me-2 top-0', boundary: 'document' }" title="Contato">
                        <b-icon icon="person-fill"/>
                    </b-dropdown-item-button> -->
                </b-dropdown>
            </div>
        </div>
        <div style="padding: 2.36em" v-else></div>
        <span class="flex-grow-1" v-if="insertFile">
            <b-overlay class="d-flex flex-column h-100" :show="inputMediaOverlay" rounded="sm">
                <div class="px-2 text-white d-flex align-items-center w-100 flex-shrink-0" style="background-color: hsl(87deg 55% 65%); font-weight: 600; padding: 0.76em">
                    <b-icon class="icon" icon="x" font-scale="3" role="button" @click="toggleInsertFile()" />
                    <div style="font-size: 1.25em">Pré-Visualizar</div>
                </div>
                <div class="insertFileBackground h-100 p-3 px-4 d-flex flex-column" style="background-color: hsl(26deg 24% 87%);">
                    <b-form class="inputFileForm h-100">
                        <div class="position-relative h-100">
                            <b-form-file
                            @input="setInputMedia"
                            class="inputFile h-100" v-bind:class="{'border-0':mediaToSend.length}"
                            :accept="fileParam"
                            drop-placeholder="SOLTAR AQUI"
                            multiple
                            >
                                <template slot="placeholder">
                                    <div>
                                        <div class="addMediaWrapper d-flex flex-column align-items-center" v-if="!mediaToSend?.length">
                                            <div><b-icon scale="3" icon="plus"/></div>
                                            <div>Adicionar</div>
                                            <div>Arquivo</div>
                                        </div>
                                    </div>
                                </template>
                                <template slot="file-name">
                                    <div class="invisible"></div>
                                </template>
                            </b-form-file>
                            <img class="position-absolute top-0 start-50 translate-middle-x mh-100 mw-100" :src="mediaToSend[mediaToSend.length-1].url" v-if="mediaToSend?.length && mediaToSend[mediaToSend.length-1].type=='image'">
                            <video class="position-absolute top-0 start-50 translate-middle-x mh-100 mw-100" :src="mediaToSend[mediaToSend.length-1].url" v-else-if="mediaToSend?.length && mediaToSend[mediaToSend.length-1].type=='video'"></video>
                            <div class="position-absolute p-3 bg-white d-flex flex-column align-items-center justify-content-center top-0 start-50 translate-middle-x mh-100 mw-100" v-else-if="mediaToSend?.length && mediaToSend[mediaToSend.length-1].type=='audio'">
                                <b-icon class="mb-3" font-scale="4" icon="file-earmark-music"/>
                                {{mediaToSend[mediaToSend.length-1].name}}
                            </div>
                            <div class="position-absolute p-3 bg-white d-flex flex-column align-items-center justify-content-center" style="height: 100%; width: 50%; left:50%; transform: translateX(-50%); top: 0" v-else-if="mediaToSend?.length && (mediaToSend[mediaToSend.length-1].type=='document' || mediaToSend[mediaToSend.length-1].type=='application')">
                                <!-- <embed style="width: 100%; height: 100%" :src="mediaToSend[mediaToSend.length - 1].url" v-if="fileExt(mediaToSend[mediaToSend.length-1].name)=='pdf'"> -->
                                <pdf style="height: 90%" class="overflow-y-auto" :src="mediaToSend[mediaToSend.length - 1].url" :page="1" v-if="fileExt(mediaToSend[mediaToSend.length-1].name)=='pdf'"></pdf>
                                <div class="vstack gap-1 align-items-center justify-content-center" v-else>
                                    <b-icon
                                        class="mb-3" 
                                        :icon="(fileExt(mediaToSend[mediaToSend.length-1].name)=='csv' || fileExt(mediaToSend[mediaToSend.length-1].name)=='xlsx') ? 'file-earmark-spreadsheet-fill' : 'file-earmark-fill'" 
                                        :class="{ 'text-success': (fileExt(mediaToSend[mediaToSend.length-1].name)=='csv' || fileExt(mediaToSend[mediaToSend.length-1].name)=='xlsx'), 'text-danger': fileExt(mediaToSend[mediaToSend.length-1].name)=='pdf'}" 
                                        font-scale="4"
                                    />
                                    {{mediaToSend[mediaToSend.length-1].name}}
                                </div>
                            </div>
                        </div>
                    </b-form>
                    <div class="position-relative" v-if="currentMedia && (currentMedia.type.includes('image') || currentMedia.type.includes('video'))">
                        <b-input class="inputFileSub" placeholder="Legenda" v-model="inputSend" @input="setCaptionMedia"/>
                        <b-icon class="inputFileSubEmoji darkenTextOnHover" icon="emoji-smile" @click="toggleDialogEmoji"/>
                        <VEmojiPicker class="inputFileSubEmojiPicker" @select="selectEmoji" lang="pt-BR" v-show="showEmojiDialog" :i18n="i18n" />
                    </div>
                </div>
                <div class="position-relative d-flex align-items-center justify-content-center flex-shrink-0" style="background-color: hsl(251deg 49% 93%); height: 80px;">
                    <b-button class="inputFileSendBtn p-3 btn-success rounded-circle text-white" @click="sendInputMedia()"><b-icon icon="cursor-fill" rotate="45" scale="2"/></b-button>
                    <span v-if="mediaToSend?.length > 4">
                        <b-icon class="position-absolute bg-primary rounded-circle text-white p-1 btn border-0" font-scale="1.5" icon="chevron-left" style="left:4em" @click="scrollInputMediaLeft"/>
                        <b-icon class="position-absolute bg-primary rounded-circle text-white p-1 btn border-0" font-scale="1.5" icon="chevron-right" style="right:4em" @click="scrollInputMediaRight"/>
                    </span>
                    <div class="inputMediaArray d-flex align-items-center justify-content-center" style="overflow-x:hidden; width: 450px">
                        <span class="position-relative" v-for="item in mediaToSend" :key="item.id">
                            <b-icon font-scale="0.75" class="position-absolute darkenTextOnHover" style="right:1em; top:0.25em; z-index:1" icon="x-circle-fill" variant="danger" @click="removeMediaToSend(item)"/>
                            <img class="me-2" :src="item.url" style="max-width: 65px; height:65px" v-if="item.type=='image'">
                            <video class="me-2" :src="item.url" style="max-width: 65px; height:65px" v-else-if="item.type=='video'"></video>
                            <div class="me-2" style="max-width: 65px; height:65px" v-else-if="item.type=='audio'" v-b-tooltip="{customClass: 'mt-5 top-0'}" :title="item.name">
                                <b-icon class="p-2 bg-white" icon="file-earmark-music" style="height: 100%; width: 100%;"/>
                            </div>
                            <div class="me-2" style="max-width: 65px; height:65px" v-else-if="item.type=='document' || item.type=='application'" v-b-tooltip="{customClass: 'mt-5 top-0'}" :title="item.name">
                                <b-icon class="p-2 bg-white" icon="file-earmark" style="height: 100%; width: 100%;"/>
                                <div style="position: absolute; left: 50%; bottom: 50%; font-size: 0.7em; color: #555; text-transform: uppercase; transform: translate(-65%,60%);">
                                    {{fileExt(item.name)}}
                                </div>
                            </div>
                        </span>
                        <b-form-file
                            @input="setInputMedia"
                            class="inputFile inputFileSmall"
                            :accept="fileParam"
                            drop-placeholder="SOLTAR AQUI"
                            multiple
                            v-if="mediaToSend?.length"
                        >
                            <template slot="placeholder">
                                <div>
                                    <div class="d-flex flex-column align-items-center">
                                        <div><b-icon scale="3" icon="plus"/></div>
                                        <div>Adicionar</div>
                                        <div>Arquivo</div>
                                    </div>
                                </div>
                            </template>
                            <template slot="file-name">
                                <div>
                                    <div class="d-flex flex-column align-items-center">
                                        <div><b-icon scale="3" icon="plus"/></div>
                                        <div>Adicionar</div>
                                        <div>Arquivo</div>
                                    </div>
                                </div>
                            </template>
                        </b-form-file>
                    </div>
                </div>
            </b-overlay>
        </span>
        <span class="d-flex flex-column h-100" v-else>
            <Whatsapp class="whatsappMsgs flex-grow-1 overflow-y-auto position-relative rounded-0" height="100%" :messages="messages" :mediaURL="mediaURL" :showLoadMoreInternalMessagesButton="!!chat" @selectedMedia="selectMedia" @loadMoreInternalMessages="loadMoreInternalMessages" />
            <div class="d-flex align-items-center justify-content-between position-relative px-2 px-sm-3 py-2 py-sm-3 bg-msgInput flex-shrink-0" v-if="!recordedMedia.url">
                <VEmojiPicker class="inputFileSubEmojiPicker inputMessageEmojiPicker" lang="pt-BR" v-show="showEmojiDialog" :i18n="i18n" @select="selectEmoji" />
                <b-icon class="me-2 text-secondary darkenTextOnHover" font-scale="2" icon="emoji-laughing" @click="toggleDialogEmoji"/>
                <form class="w-100" @submit.prevent="sendMSG">
                    <b-input-group class="d-flex align-items-center bg-white rounded-pill text-truncate">
                        <b-form-textarea
                            :class="`msgsTextarea text-wrap border-0 shadow-none text-truncate ${!chat ? 'px-sm-5 ps-3' : 'mx-sm-4 ms-3'}`"
                            placeholder="Digite uma Mensagem..." 
                            v-model="inputSend"
                            :disabled="!chat"
                            rows="1"
                            max-rows="4"
                            no-resize
                            @keydown.enter.exact.prevent="sendMSG()"
                            @input="verifyInput"
                        />
                        <template #append v-if="chat">
                            <div class="hstack">
                                <span @click="toggleQuickAnswers" v-if="operator.channelConfig?.modules?.quickAnswers && chat && quickAnswers?.length">
                                    <b-icon class="me-1 me-sm-3 text-secondary" font-scale="1.2" icon="lightning-charge-fill" role="button" />
                                </span>
                            </div>
                        </template>
                    </b-input-group>
                </form>
                <span v-if="!recorder">
                    <b-icon class="ms-1 text-secondary darkenTextOnHover" icon="cursor-fill" font-scale="2" rotate="45" @click.prevent="sendMSG()" v-if="inputSend?.length > 0" />
                    <b-icon class="ms-1 text-secondary darkenTextOnHover" icon="mic-fill" font-scale="2" @click="recAudio" v-else-if="operator && !operator.disableAudio" />
                    <!-- <b-icon class="ms-2 text-secondary darkenTextOnHover" :icon="browser=='Firefox'?'mic-fill':'mic-mute-fill'" font-scale="2" @click="recAudio" v-else-if="operator && !operator.disableAudio" /> -->
                </span>
                <span class="d-flex" v-else>
                    <!-- <b-icon class="ms-2 text-secondary" icon="cursor-fill" rotate="45" font-scale="2" @click="sendAudio" /> -->
                    <div class="ms-2 d-flex align-items-center">{{ recordingTime }}</div>
                    <b-icon class="text-danger icon darkenTextOnHover" icon="stop-fill" font-scale="2" @click="confirmAudio" />
                </span>
            </div>
            <div class="d-flex align-items-center justify-content-between position-relative" style="background-color: hsl(251deg 49% 93%); padding: 1em" v-else>
                <b-icon class="me-2 text-danger icon darkenTextOnHover" icon="trash-fill" font-scale="2" @click="stopAudio" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Cancelar" />
                <video class="rounded audioplayer w-100" style="height:3em" controls>
                    <source :src="recordedMedia.url">
                </video>
                <b-icon class="ms-2 text-secondary darkenTextOnHover" icon="cursor-fill" rotate="45" font-scale="2" @click="sendAudio" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Enviar" /> 
            </div>
        </span>
    </div>
</template>

<script type="application/javascript" :src="`${window.location.protocol}//${window.location.host}/OpusMediaRecorder.umd.js`"></script>
<script type="application/javascript" :src="`${window.location.protocol}//${window.location.host}/encoderWorker.umd.js`"></script>

<script>
import Whatsapp from './whatsapp.vue'
import { VEmojiPicker } from 'v-emoji-picker'
import api from '../services/apiService.js'
import utils from '../utils/utils.js'
import { getToken, getTokenTimestamp } from '../services/userService.js'

const workerOptions = {
    encoderWorkerFactory: function () {
        return new Worker(`${window.location.protocol}//${window.location.host}/encoderWorker.umd.js`)
    },
    OggOpusEncoderWasmPath: `${window.location.protocol}//${window.location.host}/OggOpusEncoder.wasm`,
    WebMOpusEncoderWasmPath: `${window.location.protocol}//${window.location.host}/WebMOpusEncoder.wasm`
}

export default {
    components: {
        Whatsapp,
        VEmojiPicker,
    },
    props: [
        'socket',
        'user',
        'operator',
        'timestampDiff',
        'mediaURL',
        'chat',
        'i18n',
        'internalNewMessage',
    ],
    mounted: function() {
        this.socketOnEvents()  
    },
    watch: {
        "chat": {
            handler(newChat) {
                if (!newChat) return

                if (newChat?.otherOperators[0]?.status) this.isOperatorOffline = false
                else this.isOperatorOffline = true

                this.messages = newChat.chat
                this.currentDay = 0
            }
        },
        "chat.chat": {
            handler(newMessages) {
                if (!newMessages) return

                this.messages = newMessages
            }
        },
        "chat.day": {
            handler(newDay) {
                if (!newDay) this.currentDay = 0

                this.currentDay = newDay
            }
        },
        "internalNewMessage": {
            handler(newMessage) {
                if (!newMessage) return

                if (!newMessage.checkChat) {
                    this.socket.emit('internal_message_ack_operator', { ...newMessage, statusAck: 3 })
                    this.messages.push(newMessage)
                }

                if (!document.hasFocus()) {
                    this.sendBrowserNotification({
                        ...newMessage,
                        operator: this.chat.otherOperators[0]
                    })
                }
            }
        }
    },
    data() {
        return {
            messages: [],
            insertFile: false,
            showEmojiDialog: false,
            selectedItemEmoji: null,
            inputSend: '',
            mediaToSend: [],
            recorder: false,
            chunks: [],
            recordInterval: '',
            recordingTime: '00:00',
            recordedMedia: { url: null, file: null },
            currentMedia: null,
            inputMediaOverlay: false,
            fileParam: null,
            quickAnswers: [],
            currentDay: 0,
            isOperatorOffline: true
        }
    },
    methods: {
        scrollBottomMsgsDiv() {
            const msgsdiv = document.querySelector('.msgsdiv')
            if (msgsdiv) {
                msgsdiv.scrollTo(0, msgsdiv.scrollHeight)
            }
        },
        scrollTopMsgsDiv() {
            const msgsdiv = document.querySelector('.msgsdiv')
            if (msgsdiv) {
                msgsdiv.scrollTo(0, 0)
            }
        },
        setInputMedia(arrayMedia) {
            if (arrayMedia?.length) {
                arrayMedia.forEach(e => {
                    e = new File([e], utils.replaceDiacritics(decodeURIComponent(`${e.name.replace(/\.jfif$/, '.jpeg')}`)), {
                        type: e.type 
                    })

                    if (e.size > 15000000) {
                        const input = document.querySelector('.inputFileForm')

                        if (input)
                            input.reset()

                        return this.$emit("msg", {
                            text: "O tamanho máximo permitido para envios é de 15 MB",
                            success: false
                        })
                    }
                    if (e.type == 'video/x-matroska' || e.type == 'image/webp' || !e.type) {
                        const input = document.querySelector('.inputFileForm')

                        if (input)
                            input.reset()

                        return this.$emit("msg", {
                            text: 'Extensão não suportada!',
                            success: false
                        })
                    }               

                    const ext = e.name.split('').reverse().join('').split('.')[0].split('').reverse().join('')
                    if (this.containsUppercase(ext)) {
                        e = new File([e],e.name.replace(ext,ext.toLowerCase()), { type: e.type })
                    }

                    this.inputSend = ''
                    this.currentMedia = e
                    // this.file = []
                    this.file.push(e)
                    const reader = new FileReader()
                    reader.readAsDataURL(e)
                    reader.onload = () => {
                        const dataURL = reader.result
                        // this.mediaToSend = []
                        let type = e['type'].split('/')[0]
                        if (type == "text") {
                            type = "document"
                        }
                        this.mediaToSend.push({url:dataURL.replace('base64','charset=utf-8;base64'),type,name:e['name'].replace('.jfif','.jpeg')})
                    }
                })
            }
        },
        containsUppercase(str) {
            return /^[A-Z]+$/.test(str)
        },
        setCaptionMedia(e) {
            this.currentMedia.caption = e
        },
        async sendInputMedia() {
            if (this.file?.length > 0) {
                let count = 0
                this.inputMediaOverlay = true
                await this.file.forEach(async (file) => {
                    const formData = new FormData()
                    const message = { body:'', mediaUrl:'' }
                    if (file.caption)
                        message.body = file.caption
                    let typefile = file['type'].split('/')[0]
                    if (typefile == 'text')
                        typefile = 'file'
                    // if (type=='application') {
                    //     type = 'document'
                    // }
                    const media = this.mediaToSend.find(el => el['name'] == file.name)
                    const ext = file.name.split('').reverse().join('').split('.')[0].split('').reverse().join('')
                    const msg = {
                        body: file.caption,
                        fromMe: true,
                        type: media.type,
                        mediaURL: media.url,
                        caption: message.body,
                        filename: file.name,
                        ext
                    }
                    file.filename = utils.replaceDiacritics(decodeURIComponent(file.name))
                    console.log({file})
                    formData.append('file',file)
                    const headers = { 'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile': typefile, 'clientNumber': this.user.channelId }
                    const uploadResp = await api.upload(formData,headers)
                    console.log('uploadResp',uploadResp)
                    
                    if (uploadResp.statusCode != 200) {
                        this.file = []
                        this.mediaToSend = []
                        this.inputSend = ''
                        this.inputMediaOverlay = false

                        await this.toggleInsertFile()

                        return this.$emit("msg", {
                            text: "Ocorreu um erro! Tente novamente mais tarde ou relogue!",
                            success: false,
                            countdown: 8
                        })
                    }

                    msg.mediaURL = uploadResp.file.uploadDir

                    this.messages.push(msg)

                    const url = uploadResp.file.uploadDir
                    const obj = {
                        channelId: this.user.channelId,
                        operatorIds: this.chat.operatorIds,
                        operatorId: this.operator._id,
                        mediaURL: url,
                        type: typefile, 
                        caption: message.body, 
                        filename: file['name'].split('.')[0],
                    }

                    this.socket.emit('send_internal_message', obj)

                    this.$root.$emit('last_message', { ...obj, body: 'Mídia' })
         
                    count++

                    if (count == this.file.length) {
                        this.file = []
                        this.mediaToSend = []
                        this.inputSend = ''
                        this.inputMediaOverlay = false
                        await this.toggleInsertFile()
                        this.scrollBottomMsgsDiv()
                    }
                })
            }
        },
        async recAudio() {
            if (this.chat) {
                window.MediaRecorder = OpusMediaRecorder
                
                navigator.mediaDevices.getUserMedia({ audio:true }).then(stream => {
                    this.recorder = true
                    let totalSeconds = 0
                    let seconds = 0
                    let minutes = 0

                    const pad = val => (val + "").padStart(2, "0")

                    this.recordInterval = setInterval(() => {
                        ++totalSeconds;
                        seconds = pad(totalSeconds % 60)
                        minutes = pad(parseInt(totalSeconds / 60))
                        this.recordingTime = minutes+':'+seconds
                    }, 1000)

                    this.mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/ogg;codecs=opus' }, workerOptions)
                    this.chunks = []
                    this.mediaRecorder.ondataavailable = e => this.chunks.push(e.data)
                    this.mediaRecorder.onerror = console.error
                    this.mediaRecorder.start(100)
                })
                .catch((error) => {
                    console.log('record', error)
                    switch (error.name) {
                        case 'NotFoundError':
                            this.$emit('msg', {
                                text: 'Nenhum microfone encontrado',
                                success: false
                            })
                            break
                        case 'NotAllowedError':
                        case 'SecurityError':
                            this.$emit('msg', {
                                text: 'Acesso ao microfone negado.\nPor favor, verifique as permissões do navegador',
                                success: false
                            })
                            break
                    }
                })
            }
        },
        confirmAudio() {
            clearInterval(this.recordInterval)
            this.recordingTime = ''

            this.mediaRecorder.stop()
            this.mediaRecorder.stream.getTracks().forEach(i => i.stop())

            this.recorder = false

            this.mediaRecorder.onstop = () => {
                const blob = new Blob(this.chunks, { 'type': this.mediaRecorder.mimeType || this.mediaRecorder._mimeType })
                const file = new File([blob], `audio.${this.audioExtension}`,{ type: blob.type })
                this.recordedMedia.file = file

                this.recordedMedia.url = URL.createObjectURL(blob)
            }
        },
        playAudio(url) {
            const audio = new Audio(url)
            audio.play()
        },
        stopAudio() {
            this.recordedMedia = { url:null, file:null }
        },
        async sendAudio() {
            if (this.sendingAudio) return

            this.sendingAudio = true

            try {
                const formData = new FormData()
                this.recordedMedia.file.agent = window.navigator.vendor
                formData.append('file', this.recordedMedia.file);
                const token = getToken()
                const headers = {
                    'Content-Type': 'multipart/form-data',
                    'type': 'file',
                    'typefile': 'audio',
                    'clientNumber': this.user.channelId,
                    'authorization': token,
                    'from': 'enviadas'
                }
                const uploadResp = await api.upload(formData,headers)
                const url = uploadResp.file.uploadDir
                const obj = {
                    channelId: this.user.channelId,
                    operatorId: this.operator._id,
                    operatorIds: this.chat.operatorIds,
                    mediaURL: url,
                    type: 'audio',
                    caption: '', 
                    filename: `audio${Date.now()}`,
                }

                this.socket.emit('send_internal_message', obj)

                this.$root.$emit('last_message', { ...obj, body: 'Áudio' })

                const msg = {
                    body: '',
                    fromMe: true,
                    type: 'audio',
                    mediaURL: url
                }

                this.messages.push(msg)

                this.recordedMedia = {
                    url: null,
                    file: null
                }

                this.scrollBottomMsgsDiv()
                this.sendingAudio = false
            } catch(error) {
                console.error(error)
                this.$emit('msg', { text: 'Ocorreu um erro ao enviar o audio. Tente novamente.', success: false })
                this.sendingAudio = false
            }
        },
        sendMSG(text = null) {
            if (!text) {
                if (this.inputSend.startsWith('/')) {
                    if (this.quickAnswers?.length) {
                        const quickAnswer = this.quickAnswers.find(el => el.shortcuts.includes(this.inputSend.substring(1)))
                        if (quickAnswer)
                            text = quickAnswer.content
                        else
                            text = this.inputSend
                    } else
                        text = this.inputSend
                } else
                    text = this.inputSend
            }

            if (text?.length > 0) {
                const obj = {
                    channelId: this.user.channelId,
                    operatorIds: this.chat.operatorIds,
                    operatorId: this.operator._id,
                    body: text,
                    type: 'text',
                }

                this.socket.emit('send_internal_message', obj)
                
                this.$root.$emit('last_message', obj)

                const aux = new Date()
                const date = aux.toLocaleDateString('en-US')
                
                const timestamp = Math.floor(aux.getTime() / 1000) + Math.floor(aux.getTimezoneOffset() ? aux.getTimezoneOffset() / 12 : 0)

                const msg = {
                    body: text,
                    fromMe: true,
                    timestamp,
                    statusAck: 0,
                    type: 'text',
                }

                this.messages.push(msg)

                this.inputSend = ''
                setTimeout(() => {
                    this.scrollBottomMsgsDiv()
                }, 500)
            }
            this.hideQuickAnswersDiv()
        },
        toggleInsertFile(param = null) {
            this.insertFile = !this.insertFile
            this.mediaToSend = []
            this.file = []
            this.inputSend = ''
            this.currentMedia = null
            if (param)
                this.fileParam = param
        },
        toggleDialogEmoji() {
            if (this.chat) {
                this.showEmojiDialog = !this.showEmojiDialog;
            }
        },
        selectEmoji(emoji) {
            this.inputSend += emoji.data
            if (this.currentMedia)
                this.currentMedia.caption += emoji.data
            this.toggleDialogEmoji()
        },
        async verifyInput(input) {
            if (input == "/" || input.startsWith("/")) {
                await this.getQuickAnswers(input)
                if (this.quickAnswers?.length) {
                    this.showQuickAnswersDiv()
                } else {
                    this.hideQuickAnswersDiv()
                }
            } else {
                this.hideQuickAnswersDiv()
            }
        },
        async getQuickAnswers(shortcut = null) {
            this.loadingQuickAnswers = true
            const form = {
                departmentsArray: this.operator.department,
            }

            if (shortcut) {
                shortcut = shortcut.slice(1)
                form.shortcut = shortcut
            }

            const resp = await api.getQuickAnswersByDepartments(this.operator.channelId, form)
            this.loadingQuickAnswers = false
            if (resp.statusCode !== 200)  {
                return this.quickAnswers = []
            }
            this.quickAnswers = resp.quickAnswers.filter(el => el.channelId === this.operator.channelId)
        },
        setQuickAnswer(msg) {
            this.inputSend = msg
            this.hideQuickAnswersDiv()
        },
        toggleQuickAnswers() {
            // if (this.quickAnswers.length)
            this.showQuickAnswers = !this.showQuickAnswers
        },
        showQuickAnswersDiv() {
            if (!this.showQuickAnswers)
                this.showQuickAnswers = true
        },
        hideQuickAnswersDiv() {
            if (this.showQuickAnswers)
                this.showQuickAnswers = false
        },
        fileExt(filename) {
            if (filename?.split('').reverse().join('')) {
                return filename.split('').reverse().join('').split('.')[0].split('').reverse().join('')
            }
            return ''
        },
        handleTimestamp(timestamp) {
            if (timestamp > 9999999999) return timestamp
            else return timestamp *= 1000
        },
        selectMedia(media) {
            this.$emit('selectedMedia', media)  
        },
        loadMoreInternalMessages() {
            this.currentDay++
            this.$root.$emit('loadMoreInternalMessages', {
                operatorId: this.chat.operatorIds.find(el => el !== this.operator._id),
                day: this.currentDay
            })
        },
        showNotification(messageText,contactName,contactPhotoURL) {
            if(document.hasFocus())
                return

            const notification = new Notification(contactName, { body: messageText, icon: contactPhotoURL || "https://firebasestorage.googleapis.com/v0/b/notifiqueai-app.appspot.com/o/assets%2Fdefault.jpg?alt=media&token=73be2b6f-a8a3-4407-b079-ab3918d37d8c" })
            notification.onclick = () => { 
                notification.close()
                window.parent.focus()
            }
        },
        requestNotificationPermission() {
            if (!("Notification" in window))
                alert("Esse navegador não suporta notificações!")
            if (Notification.permission == "denied") {
                alert("Notificações estão bloqueadas pelo seu navegador! \nVocê pode desbloqueá-la nas configurações do seu navegador.")
            }
            Notification.requestPermission((permission) => {
               this.browserNotification = permission
                if (permission === "granted") {
                    this.$bvModal.show("notificationConfig")
                }
            })
        },
        requestAndShowPermission() {
            Notification.requestPermission(function (permission) {
                if (permission === "granted") {
                    this.$bvModal.show("notificationConfig")
                    this.showNotification()
                }
            });
        },
        sendBrowserNotification({ operator, ...message }) {
            console.log({ message })
            if ('Notification' in window) {
                let text
                switch(message.type) {
                    case 'text':
                    case 'chat':
                        text = message.body
                    break
                    case 'video':
                        text = "Vídeo"
                    break
                    case 'image':
                        text = "Ímagem"
                    break
                    case 'audio':
                        text = "Áudio"
                    break
                    case 'location':
                        text = "Localização"
                    break
                    case 'vcard':
                        text = "Contato"
                    break
                    case 'vcardArray':
                        text = "Contatos"
                    break
                    case 'document':
                    case 'application':
                        text = "Documento"
                    break
                    case 'sticker':
                        text = "Sticker"
                    break
                    default:
                        return
                }
    
                const permission = Notification.permission

                if(permission === "granted")
                    this.showNotification(text, `Chat Interno: ${operator.nickname || operator.name}`)
                else if(permission === "default")
                    this.requestAndShowPermission()
            }
        },
        socketOnEvents() {
            this.socket.on('internal_message_ack', (data) => {
                console.log('internal_message_ack', data)
                if (
                    this.chat?.operatorIds?.length === data.operatorIds.length &&
                    this.chat?.operatorIds?.every((val) => data.operatorIds.includes(val))
                ) {
                    if (data._id) {
                        const index = this.messages.findIndex(el => el._id === data._id)
                        if (index > -1) {
                            this.messages[index].statusAck = data.statusAck
                        }

                        return
                    }

                    const index = this.messages.findLastIndex(el => !el.statusAck || el.statusAck === data.statusAck - 1)
                    if (index > -1) {
                        this.messages[index].statusAck = data.statusAck
                    }
                }
            })
        }
    }
}
</script>

<style>
    .whatsappMsgs > .rounded {
        border-radius: 0 !important;
    }
    .whatsappMsgs .innerDiv {
        height: 0;
    }
</style>

<style scoped>
    .msgsTextarea::-webkit-scrollbar {
        display: none;
    }
    .msgsTextarea {
        -ms-overflow-style: none;
        scrollbar-width: none;
        min-height: 3em;
    }
    .toggle-emoji {
        position: absolute;
        bottom: 0;
        right: 8px;
        background-color: transparent;
        border: none;
        color: #aaa;
        box-shadow: none !important;
        cursor: default;
    }
    .toggle-emoji-icon {
        cursor: pointer;
        font-size: 2em;
    }
    .toggle-emoji-icon:hover {
        color: #555;
    }
    .inputFileSub{
        background-color: transparent;
        border: none;
        border-bottom: 2px solid hsl(245deg 12% 63%) !important;
        border-radius: 0;
    }
    .inputFileSubEmoji{
        position: absolute;
        right: 0;
        top: 1em;
    }
    .inputFileSubEmojiPicker{
        position: absolute;
        top: -25em;
        right: 1em;
        z-index: 1;
        flex-direction: column-reverse !important;
    }
    .inputMessageEmojiPicker{
        right: unset;
        top: -26em;
        left: 0;
    }
    .inputReactEmojiPicker{
        position: absolute;
        flex-direction: column-reverse !important;
        z-index: 1;
        /* bottom: 0; */
        top: 0;
        left: 1.5em;
    }
    .inputFileSubEmoji{
        position: absolute;
        right: 0;
        top: 1em;
    }
    .inputFileSubEmojiPicker{
        position: absolute;
        top: -25em;
        right: 1em;
        z-index: 1;
        flex-direction: column-reverse !important;
    }
    .inputMessageEmojiPicker{
        right: unset;
        top: -26em;
        left: 0;
    }
    .panelicon{
        font-size: 1.4em;
        color: #222;
    }
    .panelicons:hover .panelicon{
        color: #555;
    }
    .panelicons:not(:last-child) {
        margin-right: 20px;
    }
    .dropdownanexes{
        background-color: transparent !important;
        border-color: transparent !important;
        flex-direction: column;
        min-width: fit-content;
        cursor: default;
        margin-left: -0.3em;
        z-index: 2;
    }
    .dropdownanexes .b-icon{
        color: #fff;
        font-size: 1.5em !important;
    }
    .dropdownanexes svg{
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    }
    .dropdownanexesitem{
        margin: 15px 0;
        margin-left: -5px;
        box-shadow: 1.4px 2px 5px #555 !important;
    }
    .dropdownanexesitem:hover{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
    .dropdownanexesitembtn{
        padding: 12px;
        padding-bottom: 8px;
        width: fit-content;
        background-color: transparent !important;
    }
</style>
<template>
    <div>
        <div>
            <div class="border-bottom py-2 px-4 darkenOnHover" :style="depth && 'border-bottom-style: dashed !important'" role="button" v-b-toggle="`collapse-category-${_id}`">
                <span v-if="depth">
                    <span v-for="item in depth" :key="item">
                        &nbsp; &nbsp;&nbsp; 
                    </span>    
                </span>
                {{ name }}
            </div>
            <b-collapse :id="`collapse-category-${_id}`">
                <div v-if="subcategories?.length">
                    <operator-catalog
                        v-for="subcategory in subcategories" :key="subcategory._id"
                        :subcategories="subcategory.subcategories"
                        :name="subcategory.name"
                        :_id="subcategory._id"
                        :depth="depth + 1"
                        :products="subcategory.products"
                    />
                </div>
                <div v-if="products?.length">
                    <!-- <b-tabs class="mt-2" :title-link-class="['text-secondary']" align="center">
                        <b-tab title="Menor Preço"> -->
                            <operator-products
                                :products="products"
                            />
                        <!-- </b-tab>
                    </b-tabs> -->
                </div>
                <div class="text-center text-secondary p-2 border-bottom" v-else-if="!subcategories?.length">
                    Nenhum produto encontrado!
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import OperatorProducts from './operatorProducts.vue'

export default {
    name: 'operator-catalog',
    components: {
        OperatorProducts
    },
    props: [
        'subcategories', 'name', '_id', 'depth', 'products'
    ]
}
</script>
<template>
    <div class="h-100">
        <div class="bg-white rounded shadow-sm d-flex justify-content-center py-4" v-if="loadingKanbanCards">
            <b-spinner variant="purple"></b-spinner>
        </div>
        <div class="hstack gap-3 m-0 p-3 h-100 overflow-x-auto" v-else-if="kanbanSteps.length">
            <!-- <div class="vstack gap-3 bg-white h-100 rounded shadow-sm p-3" style="min-width: 275px" v-for="(step, stepIndex) in kanbanSteps" :key="step._id"> -->
            <div class="vstack gap-3 bg-white h-100 rounded shadow-sm p-3" v-for="(step, stepIndex) in kanbanSteps" :key="step._id">
                <div>
                    <div class="fs-5">
                        {{ step.name }}
                    </div>
                </div>
                <draggable :id="stepIndex" tag="div" v-model="step.cards" group="cards" handle=".handle" class="vstack gap-3 flex-grow-0 h-100" @add="addingToStep">
                    <div class="handle vstack rounded shadow-sm flex-grow-0" :class="{ 'bg-green': card.inAttendance, 'bg-red': !card.inAttendance }" v-for="card in step.cards" :key="card._id">
                        <div class="hstack gap-2 p-3 border-bottom">
                            <b-avatar class="text-bg-secondary" :src="card.avatar" size="lg"></b-avatar>
                            <div class="vstack text-white">
                                <div class="fs-5 box-clamp-1">
                                    {{ card.contactName }}
                                </div>
                                <div class="box-clamp-1">
                                    {{ card.contactNumber | formatNumber }}
                                </div>
                            </div>
                            <span class="tagSpan position-relative" role="button" v-if="card.tag && getTagInfo(card.tag)">
                                <b-icon class="rounded-circle p-2-5 darkenOnHover text-light" :style="`background-color: ${getTagInfo(card.tag)?.color}`" font-scale="3" icon="tag"></b-icon>
                                <div class="tagSpanHover position-absolute mt-2 py-1 px-2 rounded pe-auto text-light" :style="`background-color: ${getTagInfo(card.tag)?.color}`">
                                    {{ getTagInfo(card.tag)?.name }}
                                </div>
                            </span>
                        </div>
                        <div class="hstack gap-2 p-3 justify-content-between">
                            <div class="vstack text-white">
                                <div class="fs-5 box-clamp-1">
                                    {{ card.operatorName }}
                                </div>
                                <div class="small-text" v-if="card.operatorName">
                                    Operador
                                </div>
                            </div>
                            <div class="hstack gap-3">
                                <b-button class="bg-transparent p-1 rounded-circle border-0" variant="none" v-b-tooltip.hover.bottom="{ customClass: 'top-0 mt-1', boundary: 'document' }" title="Ver Histórico" @click="selectContact(card, true)">
                                    <b-iconstack font-scale="1.5">
                                        <b-icon class="text-dark text-opacity" style="--bs-text-opacity: .3;" stacked scale="1.5" icon="circle-fill"></b-icon>
                                        <b-icon stacked icon="file-earmark-text" variant="light"></b-icon>
                                    </b-iconstack>
                                </b-button>
                                <b-button class="bg-transparent p-1 rounded-circle border-0" variant="none" v-b-modal.modalAttendance v-b-tooltip.hover.bottom="{ customClass: 'top-0 mt-1', boundary: 'document' }" title="Ver Atendimento" v-if="card.inAttendance" @click="getAttendanceMessages(card)">
                                    <b-iconstack font-scale="1.5">
                                        <b-icon class="text-dark text-opacity" style="--bs-text-opacity: .3;" stacked scale="1.5" icon="circle-fill"></b-icon>
                                        <b-icon stacked icon="eye" variant="light"></b-icon>
                                    </b-iconstack>
                                </b-button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="text-secondary text-center" v-else>
                        Nenhum card encontrado!
                    </div> -->
                </draggable>
            </div>
        </div>
        <div class="bg-white rounded shadow-sm d-flex justify-content-center py-4" v-else>
            Nenhuma fase encontrada!
        </div>
        <b-modal id="modalAttendance" ref="modalAttendance" hide-footer title="Visualizar Atendimento" header-class="py-0" v-if="selectedContact">
            <div class="d-flex align-items-center">
                <b-avatar :src="selectedContact.photoURL" class="bg-secondary text-white" size="3em"></b-avatar>
                <div class="ms-1 text-purple fw-semibold">
                    {{ selectedContact.name }}
                </div>
            </div>
            <div class="mt-3" v-if="attendanceMsgs">
                <Whatsapp :messages="attendanceMsgs.messages" :mediaURL="mediaURL" :height="'25em'" :timestampDiff="timestampDiff" />
            </div>
        </b-modal>
        <b-modal id="attendanceHistory" ref="attendanceHistory" title="Histórico de Atendimento" header-class="py-0" body-class="p-0" hide-footer v-if="selectedContact">
            <div class="d-flex align-items-center p-3">
                <b-avatar class="rounded-circle attendanceHistoryAvatar bg-secondary text-white" style="width: 3em; height: 3em;" :src="selectedContact.photoURL"></b-avatar>
                <div class="ms-2">
                    <div class="fw-bold box-clamp-1">
                        {{ selectedContact.name }}
                    </div>
                    <Steps
                        class="text-white mt-1 w-100"
                        variant="pink"
                        :steps="kanbanSteps?.map((step, index) => ({ _id: step._id, tooltipText: `Etapa ${$options.filters.pad(index + 1, 2)} ${step.name}` }))"
                        :currentStepIndex="kanbanSteps?.findIndex(el => el._id == selectedContact.stepId) + 1 || 0"
                        style="max-width: 10rem"
                    />
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-between text-secondary border-top border-bottom py-2 px-3">
                <div class="d-flex align-items-center">
                    <b-icon class="me-1" icon="whatsapp"></b-icon>
                    <div>{{ selectedContact.number }}</div>
                </div>
                <div class="d-flex align-items-center" v-if="selectedContact.state">
                    <b-icon class="me-1" icon="geo-alt"></b-icon>
                    <div>{{ parseState(selectedContact.state) }}</div>
                </div>
                <div class="d-flex align-items-center" v-if="protocol.attStartDate"> <!-- attendance start -->
                    <div class="text-capitalize">{{ protocol.attStartDate | date('MMM DD [●] HH:mm:ss') }}</div>
                </div>
            </div>
            <div v-if="selectedContact.attendances?.length">
                <div class="py-2 pb-3 px-3">
                    <b-form-group label-class="text-purple" label="Protocolo:">
                        <b-form-select class="w-100 py-1 text-secondary" style="border-color: #ccc; border-radius: 0.3em" @change="pickProtocol">
                            <b-form-select-option :value="protocol" v-for="protocol in selectedContact.attendances" :key="protocol._id">
                                {{ protocol.protocol }} - 
                                <span class="text-capitalize">
                                    {{ (protocol.attendanceStartTime || protocol.createdAt) | date('DD/MM/YY [●] HH:mm:ss') }}
                                </span>
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="border-top" v-if="protocol._id">
                    <div class="bg-light rounded p-2 m-3">
                        <h5 class="mb-3 text-purple fw-normal">Status do Atendimento</h5>
                        <b-row>
                            <b-col>
                                <div class="fw-bold  text-purple">Início</div>
                                <span class="text-purple text-capitalize">{{ protocol.attendanceStartTime | date('MMM DD [●] HH:mm:ss') }}</span>
                            </b-col>
                            <b-col>
                                <div class="fw-bold text-purple">Fim</div>
                                <span class="text-purple text-capitalize">{{ protocol.attendanceEndTime | date('MMM DD [●] HH:mm:ss') }}</span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-purple">
                                <div class="fw-bold">Status</div>
                                <div class="text-purple" v-if="protocol.status">
                                    {{ protocol.status | translateStatus }}
                                </div>
                                <div v-else>
                                    ----
                                </div>
                            </b-col>
                            <b-col>
                                <div class="fw-bold text-purple">Canal</div>
                                <div class="text-purple">{{ protocol.channelNumber || '----' }}</div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div class="fw-bold text-purple">Operador</div>
                                <div class="text-purple" v-if="!protocol.operators?.length">{{ protocol.operatorName || getOperatorName(protocol.operatorId) || '----' }}</div>
                                <div class="text-purple" v-else>
                                    <span v-for="(op, i) in protocol.operators" :key="op._id">
                                        {{ op.operatorName || getOperatorName(op.operatorId) }}
                                        <span v-if="i + 1 < protocol.operators.length">
                                            <b-icon icon="arrow-right"></b-icon>
                                        </span>
                                    </span>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="fw-bold text-purple">Telefone</div>
                                <div class="text-purple">{{ selectedContact.number || '----' }}</div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div class="fw-bold text-purple">Contato</div>
                                <div class="text-purple">{{ selectedContact.name || '----' }}</div>
                            </b-col>
                            <b-col>
                                <div class="fw-bold text-purple">Gênero</div>
                                <div class="text-purple">{{ parseGender(selectedContact.genre) || '----' }}</div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div class="fw-bold text-purple">Estado</div>
                                <div class="text-purple">{{ selectedContact.state || '----' }}</div>
                            </b-col>
                            <b-col v-if="selectedContact.email">
                                <div class="fw-bold text-purple">Email</div>
                                <div class="text-purple">{{ selectedContact.email || '----' }}</div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="bg-light rounded p-2 m-3" v-if="protocol.messages && protocol.messages.length">
                        <h5 class="mb-3 text-purple fw-normal">Chat do Atendimento</h5>
                        <Whatsapp :messages="protocol.messages" :height="'15em'" :mediaURL="mediaURL" :timestampDiff="timestampDiff" :user="user" />
                        <div class="position-absolute" style="right: 2000%">
                            <div id="printHeader" class="bg-grey-2 py-4 px-5" style="width: 62em;">
                                <b-img style="width: auto; height: 3em;" :src="user.iconURL ? user.iconURL : require(`../assets/images/logomarca/logomarca-dark.png`)"></b-img>
                                <!-- <div>
                                    <div class="small-text fw-bold">Protocolo de Impressão</div>
                                    <div class="smaller-text text-secondary">
                                        {{ protocol.protocol }}
                                    </div>
                                </div> -->
                            </div>
                            <div id="printFooter" class="bg-grey-2 py-4 px-5" style="width: 62em;">
                                <b-img style="width: auto; height: 2.5em;" :src="user.iconURL ? user.iconURL : require(`../assets/images/logomarca/logomarca-dark.png`)"></b-img>
                            </div>
                        </div>
                        <div class="d-none">
                            <div class="d-flex flex-column w-100 h-100" id="element-to-convert">
                                <div class="w-100">
                                    <div class="d-flex justify-content-between pb-4">
                                        <div class="d-flex align-items-center position-relative">
                                            <b-avatar class="rounded-circle bg-secondary text-white" size="3.5em" :src="selectedContact.photoURL"></b-avatar>
                                            <span class="position-absolute" style="left: 3.75em; top: 0em;">
                                                <div class="rounded-pill px-2 py-1 d-flex align-items-center justify-content-center text-white" :style="`background-color: ${getTagInfo(selectedContact.tag)?.color}; font-size: 0.46em`" role="button" v-if="selectedContact.tag && getTagInfo(selectedContact.tag)">
                                                    <b-icon icon="circle-fill"></b-icon>
                                                    &#8205;
                                                    <div class="ms-1 text-uppercase">
                                                        {{ getTagInfo(selectedContact.tag)?.name }}
                                                    </div>
                                                </div>
                                            </span>
                                            <div class="ms-2">
                                                <div class="fw-bold">{{ selectedContact.name }}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <b-row>
                                                <b-col class="d-flex align-items-center justify-content-center fw-semibold">
                                                    <b-icon class="me-2" icon="whatsapp"></b-icon>
                                                    <div class="text-capitalize">{{ selectedContact.number || '----' }}</div>
                                                </b-col>
                                            </b-row>
                                            <b-row class="smaller-text">
                                                <b-col class="d-flex align-items-center justify-content-center">
                                                    <b-icon class="me-2" icon="calendar-4"></b-icon>
                                                    <div class="text-capitalize">{{ protocol.attStartDate | date('MMM DD [●] HH:mm:ss') }}</div>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </div>
                                    <div class="w-100 border-top border-bottom py-3" style="font-size: 0.95em;">
                                        <span class="fw -bold">
                                            Protocolo: 
                                        </span>
                                        {{ protocol.protocol }}
                                    </div>
                                    <div class="py-4 border-bottom">
                                        <h5 class="mb-4 fw-bold">Status do Atendimento</h5>
                                        <b-row>
                                            <b-col>
                                                <span class="fw-bold">Início: </span>
                                                <span class="text-capitalize">{{ protocol.attStartDate | date('MMM DD [●] HH:mm:ss') }}</span>
                                            </b-col>
                                            <b-col>
                                                <span class="fw-bold">Fim: </span>
                                                <span class="text-capitalize">{{ protocol.attFinishDate | date('MMM DD [●] HH:mm:ss') }}</span>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <span class="fw-bold">Status: </span>
                                                <span class="text-capitalize" v-if="protocol.attStatus">
                                                    {{ protocol.attStatus | translateStatus }}
                                                </span>
                                                <div v-else>
                                                    ----
                                                </div>
                                            </b-col>
                                            <b-col>
                                                <span class="fw-bold">Canal: </span>
                                                <span class="text-capitalize">{{ protocol.attChannel || '----' }}</span>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <span class="fw-bold">Contato: </span>
                                                <span class="text-capitalize">{{ selectedContact.name || '----' }}</span>
                                            </b-col>
                                            <b-col>
                                                <span class="fw-bold">Telefone: </span>
                                                <span class="text-capitalize">{{ selectedContact.number || '----' }}</span>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <span class="fw-bold">Estado: </span>
                                                <span class="text-capitalize">{{ parseState(selectedContact.state) }}</span>
                                            </b-col>
                                            <b-col>
                                                <span class="fw-bold">Gênero: </span>
                                                <span class="text-capitalize">{{ parseGender(selectedContact.genre) || '----' }}</span>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col v-if="selectedContact.email">
                                                <span class="fw-bold">Email: </span>
                                                <span class="text-capitalize">{{ selectedContact.email || '----' }}</span>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div class="mt-4 pb-2">
                                        <h5 class="mb-4 fw-bold">Chat do Atendimento</h5>
                                        <Whatsapp class="pdfPrint bg-secondary" :messages="protocol.messages" :height="'100%'" :mediaURL="mediaURL" :timestampDiff="timestampDiff" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-secondary text-center py-3" v-else>
                Nenhum atendimento encontrado!
            </div>
        </b-modal>
    </div>
</template>

<script>
import api from '../services/apiService.js'
import utils from '../utils/utils.js'
import draggable from 'vuedraggable'
import Steps from './steps.vue'
import Whatsapp from './whatsapp.vue'

export default {
    components: {
        draggable,
        Steps,
        Whatsapp
    },
    props: [
        'user',
        'loadingKanbanCards',
        'kanbanSteps',
        'addingToStep',
        'getTagInfo',
        'timestampDiff',
        'mediaURL'
    ],
    data() {
        return {
            attendanceMsgs: [],
            selectedContact: {},
            protocol: {},
            operators: [],
        }
    },
    mounted() {
        this.onCloseModal()
        this.getOperators()
    },
    methods: {
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.selectedContact = {}
                this.attendanceMsgs = []
                this.protocol = {}
            })
        },
        async getAttendanceMessages(card) {
            this.selectContact(card)
            const attendanceId = card.attendances.at(-1)
            if (attendanceId) {
                const resp = await api.getProtocolMessages(attendanceId)
                if(resp.statusCode == 200) {
                    this.attendanceMsgs = resp.messages
                } else {
                    this.$emit("msg", {
                        text: "Ocorreu um erro ao carregar mensagens!",
                        success: false
                    })
                }
            }
        },
        async selectContact(card, openHistoryModal = false) {
            if (card) {
                console.log({card})
                const resp = await api.getContact(card.contactId)
                if(resp.statusCode === 200) {
                    const step = this.kanbanSteps.find(el => el._id === card.stepId)
                    if(step)
                        resp.contact.stepId = step._id
                    this.selectedContact = resp.contact

                    const attendances = []

                    for (let i = 0; i < card.attendances.length; i++) {
                        const el = card.attendances[i]
                        try {
                            const messagesResp = await api.getProtocolMessages(el)
                            if (messagesResp.statusCode == 200) {
                                const attendanceResp = await api.getOldAttendances(this.user.channelId || this.user.roleId, {
                                    search: messagesResp.messages?.protocol
                                })
                                if (attendanceResp.statusCode == 200) {
                                    attendances.push(attendanceResp.attendances[0])
                                }
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    }


                    this.$set(this.selectedContact,'attendances',attendances)

                    if (openHistoryModal && this.$refs['attendanceHistory'])
                        this.$refs['attendanceHistory'].show()
                } else {
                    this.$emit("msg", {
                        text: "Ocorreu um erro ao carregar contato!",
                        success: false
                    })
                }
            }
        },
        async pickProtocol(protocol) {
            this.protocol = protocol
            const resp = await api.getProtocolMessages(protocol._id)
            // console.log('resp protocol messages',resp)
            this.$set(this.protocol,'messages',resp.messages?.messages)
        },
        parseState(state) {
            return utils.siglaPraEstadoCompleto(state) || 'N/D'
        },
        parseCountry(countryCode) {
            return utils.getCountryName(countryCode) || 'N/D'
        },
        parseGender(gender) {
            const genderMap = {
                'M': 'Masculino',
                'F': 'Feminino',
            }
            return genderMap[gender] || 'Não Identificado'
        },
        getOperatorName(operatorId) {
            return this.operators.find(el => el._id === operatorId)?.name || null
        },
        async getOperators() {
            const resp = await api.getOperators(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.operators = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))

            this.operators = resp.operators
        },
    }
}
</script>

<style scoped>
    .tagSpanHover {
        display: none;
    }
    .tagSpan:hover .tagSpanHover {
        display: block;
    }
</style>